import React, { useEffect, useState } from 'react'
import cloneDeep from 'lodash/cloneDeep'
import isUndefined from 'lodash/isUndefined'
import zip from 'lodash/zip'
import { Button, Progress, Spinner } from 'reactstrap'
import { navigate } from 'gatsby'
import { useDispatch, useSelector } from 'react-redux'

import CONFIG from '../config'
import ImageZoom from '../components/common/ImageZoom'
import Layout from '../components/layouts/Layout'
import { ConsultationsState, consultationsSelector, fetchImagesAction } from '../hasura/slices/consultations'
import { NotificationId, setNotificationAction } from '../hasura/slices/notifications'
import { Textarea } from '../components/common/Input'
import { UsersState, slackMessageAction, usersSelector } from '../hasura/slices/users'
import { hasWindow, patientInfo, pluralize, searchQueryParams } from '../lib/helpers'

import {
  CasesState,
  casesSelector,
  fetchCaseAction,
  fetchCaseQuestionAction,
  unsetCaseAction,
  updateCaseQuestionAction,
} from '../hasura/slices/cases'

// @ts-ignore
import nextIcon from '../../lib/images/next-right-dark.png'

const mediumZoom = hasWindow ? require('medium-zoom') : undefined

interface AiTaskResponse {
  index: number
  text?: string
  rectangle?: any
}
export default function AiTask() {
  const { accessToken, user }: UsersState = useSelector(usersSelector)
  const { caseQuestion, radimalCase }: CasesState = useSelector(casesSelector)
  const { presignedCaseImageUrls, presignedTaggingImageUrl }: ConsultationsState = useSelector(consultationsSelector)

  const [idx, setIdx] = useState(0)
  const [text, setText] = useState<string | undefined>()

  const zoom = React.useRef(hasWindow ? mediumZoom.default({ margin: 48, background: 'black' }) : undefined)

  const dispatch = useDispatch()
  const questions = caseQuestion?.case_questions_denormalized || []
  const currentQuestion = questions[idx]
  const currentCaseId = currentQuestion?.id

  const [rectangle, setRectangle] = useState<any>()

  useEffect(() => {
    if (!currentQuestion?.imageName) return

    dispatch(fetchImagesAction([currentQuestion.imageName], undefined, true))
  }, [currentQuestion])

  useEffect(() => {
    const $ = document.querySelector.bind(document)
    const $screenshot = $('#screenshot')
    const $draw = $('#draw')
    const $marquee = $('#marquee')
    const $boxes = $('#boxes')

    if (!$screenshot || !$draw || !$marquee || !$boxes) return

    let startX = 0
    let startY = 0
    const marqueeRect = { x: 0, y: 0, width: 0, height: 0 }

    $marquee?.classList.add('hide')
    $screenshot?.addEventListener('pointerdown', startDrag)

    function startDrag(ev: any) {
      if (ev.button === 1) {
        const rect = hitTest(ev.layerX, ev.layerY)
        if (rect) setRectangle(rect)
        return
      }
      window.addEventListener('pointerup', stopDrag)
      $screenshot?.addEventListener('pointermove', moveDrag)
      $marquee?.classList.remove('hide')
      startX = ev.layerX
      startY = ev.layerY
      // @ts-ignore
      drawRect($marquee, startX, startY, 0, 0)
    }

    function stopDrag(ev: any) {
      $marquee?.classList.add('hide')
      window.removeEventListener('pointerup', stopDrag)
      $screenshot?.removeEventListener('pointermove', moveDrag)
      if (ev.target === $screenshot && marqueeRect.width && marqueeRect.height) {
        setRectangle(marqueeRect)
        redraw(marqueeRect)
      }
    }

    function moveDrag(ev: any) {
      let x = ev.layerX
      let y = ev.layerY
      let width = startX - x
      let height = startY - y
      if (width < 0) {
        width *= -1
        x -= width
      }
      if (height < 0) {
        height *= -1
        y -= height
      }
      Object.assign(marqueeRect, { x, y, width, height })
      drawRect($marquee, marqueeRect)
    }

    const hitTest = (x: any, y: any) =>
      [rectangle].find((rect) => x >= rect.x && y >= rect.y && x <= rect.x + rect.width && y <= rect.y + rect.height)

    const redraw = (marqueeRect: any) => {
      $boxes.innerHTML = ''
      $boxes.appendChild(drawRect(document.createElementNS('http://www.w3.org/2000/svg', 'rect'), marqueeRect))
    }

    const drawRect = (rect: any, data: any) => {
      if (!data) return

      const { x, y, width, height } = data
      rect.setAttributeNS(null, 'width', width)
      rect.setAttributeNS(null, 'height', height)
      rect.setAttributeNS(null, 'x', x)
      rect.setAttributeNS(null, 'y', y)
      return rect
    }

    const savedRectangle = caseQuestion?.case_responses_denormalized[idx]?.rectangle
    $marquee?.classList.add('hide')
    if ($boxes.firstChild) $boxes.removeChild($boxes.firstChild)
    if (!savedRectangle) return

    $marquee?.classList.remove('hide')
    drawRect($marquee, savedRectangle)
  }, [currentQuestion])

  useEffect(() => {
    if (currentCaseId) {
      dispatch(fetchCaseAction(accessToken, currentCaseId, true))
    } else {
      dispatch(unsetCaseAction())
    }
  }, [currentCaseId])

  useEffect(() => {
    const id = parseInt(searchQueryParams('i') || '', 10)
    if (!accessToken || !user || !id) return

    dispatch(fetchCaseQuestionAction(accessToken, id))
  }, [accessToken, user])

  useEffect(() => {
    const response = caseQuestion?.case_responses_denormalized[idx] || {}
    const { text, rectangle } = response
    setText(text)
    setRectangle(rectangle)
  }, [idx])

  const handleBack = () => {
    setIdx(idx - 1)
    saveResponse(false)
  }

  const handleNext = () => {
    const complete = !questions[idx + 1]
    if (complete) {
      navigate('/cases')
    } else {
      setIdx(idx + 1)
    }
    saveResponse(complete)
  }

  const saveResponse = (complete: boolean) => {
    const responses: AiTaskResponse[] = cloneDeep(caseQuestion?.case_responses_denormalized)
    const index = responses.findIndex((r) => r.index === idx)
    if (index > -1) {
      if (text) responses[index].text = text
      if (rectangle) responses[index].rectangle = rectangle
    } else {
      responses.push({ index: idx, text, rectangle })
    }
    if (complete) {
      const msg = zip(
        caseQuestion?.case_questions_denormalized.map((c: any) => c.text),
        responses.map((c: any) => c.text)
      )
        .map((c: any) => `${c[0]}: ${c[1]}`)
        .join('\n')
      dispatch(slackMessageAction('ai-report', `${msg}\n- responses from ${user?.display_name}`))
      dispatch(setNotificationAction(NotificationId.CaseQuestionsCompleted))
    }
    dispatch(updateCaseQuestionAction(accessToken, caseQuestion!.id, responses, complete))
  }
  return (
    <Layout irxMode>
      {!isUndefined(idx) && currentQuestion ? (
        <div
          className="d-flex flex-column max-width-1100px justify-content-between mx-auto"
          style={{ height: `calc(100vh - ${CONFIG.HEADER_HEIGHT}px)` }}
        >
          <div id="binary-tagger-progress" className="flex-center z-100">
            <Progress
              className="flex-grow mr-2 pointer"
              onClick={(e) => setIdx(Math.floor((e.nativeEvent.offsetX * questions.length) / e.currentTarget.clientWidth))}
              value={(idx * 100) / questions.length}
            />

            <p style={{ minWidth: '' }} className="text-dark-bg text-s mb-0 text-right single-line">
              {idx + 1} of {questions.length}
            </p>
          </div>

          <div className="d-flex">
            <div className="d-flex justify-content-center align-items-start w-100 gap-100px">
              <div className="min-width-350px max-width-600px" style={{ flex: 3 }}>
                <p className="text-dark-bg">{currentQuestion.text}</p>

                {currentQuestion.imageName ? (
                  <div className="width-500px height-500px">
                    <div className="width-fit-content height-fit-content border border-white">
                      <svg
                        className="position-absolute"
                        width="500px"
                        height="500px"
                        viewBox="0 0 500 500"
                        id="draw"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect id="marquee" x="450" y="420" width="150" height="150" />
                        <g id="boxes"></g>
                      </svg>

                      <img
                        draggable={false}
                        id="screenshot"
                        className="max-width-500px max-height-500px w-100 h-100 position-absolute"
                        style={{ zIndex: 1 }}
                        src={presignedTaggingImageUrl}
                      />
                    </div>
                  </div>
                ) : (
                  <Textarea
                    autoFocus
                    className="mb-1 data-hj-allow w-100 min-height-250px"
                    dark
                    onChange={(e) => setText(e.target.value)}
                    placeholder="Response..."
                    value={text || ''}
                  />
                )}

                <div className="mt-3 d-flex">
                  <Button onClick={handleBack} disabled={idx === 0} className="mr-2" color="dark">
                    Back
                  </Button>

                  <Button onClick={handleNext} disabled={!text && !rectangle} color="primary">
                    Save & continue
                  </Button>
                </div>
              </div>
            </div>

            {radimalCase && (
              <div style={{ flex: 7 }}>
                <div className="mb-2 text-dark-bg">
                  <p className="m-0">{patientInfo(radimalCase.patient)}</p>
                  <p className="m-0 text-xs">
                    {pluralize('image', radimalCase.medical_images.length)} <span className="font-italic">(click to zoom)</span>
                  </p>
                </div>

                <div className="d-flex gap-30px flex-wrap">
                  {radimalCase.medical_images
                    .filter((m) => m.aws_s3_url)
                    .map((m) => presignedCaseImageUrls.find((p) => p.includes(m.aws_s3_url!)))
                    .filter((src) => src)
                    .map((src, idx) => (
                      <ImageZoom px={300} key={idx} src={src!} zoom={zoom.current} />
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <Spinner color="primary" className="center" />
      )}
    </Layout>
  )
}
